import * as React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import * as styles from "./navigation.module.scss"

const Navigation = () => {

  const cover = 'url(/sd-logo.png) 50% calc(50% + (var(--header-height)/2) ) / 96px no-repeat #1C1C1C'

  return (

    <nav className={styles.container}>

      <div className={styles.block}>
        <div className={styles.heading}>
          <AniLink cover bg={cover} direction="up" to="/oxford-st/level-2">Level 2</AniLink>
        </div>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/oxford-st/level-2">Sports</AniLink>
          </li>
          <ul>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/level-2">Swim</AniLink>
            </li>
          </ul>
        </ul>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/oxford-st/level-2">Outdoor</AniLink>
          </li>
        </ul>
        <ul className={styles.key}>
          <li>
            <svg width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.7959 4.16945C14.7959 3.58644 14.9665 3.01652 15.286 2.53176C15.6056 2.047 16.0598 1.66918 16.5912 1.44607C17.1227 1.22296 17.7074 1.16458 18.2716 1.27832C18.8357 1.39206 19.3539 1.67281 19.7607 2.08506C20.1674 2.49732 20.4444 3.02256 20.5566 3.59437C20.6688 4.16618 20.6112 4.75888 20.3911 5.29751C20.171 5.83615 19.7982 6.29653 19.3199 6.62043C18.8417 6.94434 18.2794 7.11722 17.7042 7.11722V10.8587" stroke="#F8F8F8" strokeWidth="2.19022" strokeMiterlimit="10"/>
              <path d="M17.7696 24.0008H3.38916C1.97565 24.0008 1.46748 21.5787 2.68824 20.6514L8.02689 16.5892L15.8012 10.6793C16.3862 10.2252 17.0703 9.9834 17.7696 9.9834C18.4689 9.9834 19.153 10.2252 19.738 10.6793L27.5123 16.5892L32.851 20.6514C34.0717 21.5787 33.5636 24.0008 32.1501 24.0008H17.7696Z" stroke="#F8F8F8" strokeWidth="2.19022" strokeMiterlimit="10"/>
            </svg>
          </li>
          <li>
            <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.9 9.98012C10.82 10.5301 10.46 10.8501 9.92 10.8501C9.25 10.8501 8.82 10.6901 8.15 10.6901C7.86 10.6901 7.58 10.7201 7.31 10.7801C7.97 10.5101 8.31 10.0001 8.31 9.30012C8.31 9.13012 8.3 8.98012 8.28 8.84012H10.2V8.03012H8.1C8.04 7.76012 7.99 7.49012 7.99 7.16012C7.99 6.45012 8.39 6.08012 9.19 6.08012C9.86 6.08012 10.3 6.43012 10.42 7.05012L12.05 6.72012C11.79 5.51012 10.8 4.87012 9.15 4.87012C7.4 4.87012 6.33 5.72012 6.33 7.17012C6.33 7.49012 6.38 7.76012 6.44 8.03012H5.57V8.84012H6.62C6.66 9.04012 6.68 9.26012 6.68 9.50012C6.68 10.3501 6.22 10.8901 5.25 11.1601L5.57 12.0801C6.22 11.9601 6.88 11.8901 7.47 11.8901C8.69 11.8901 9.2 12.1701 10.24 12.1701C11.45 12.1701 12.3 11.4301 12.49 10.2001L10.9 9.98012Z" fill="#F8F8F8"/>
              <path d="M8.8711 1.03906H9.16528C15.0378 1.23888 18.6013 7.6998 15.7483 12.823L9.93127 23.4414C9.8045 23.6019 9.643 23.7317 9.4589 23.8209C9.27481 23.9101 9.07289 23.9564 8.86832 23.9564C8.66375 23.9564 8.46184 23.9101 8.27775 23.8209C8.09365 23.7317 7.93215 23.6019 7.80538 23.4414L1.98833 12.8286C-0.831384 7.6776 2.69881 1.24442 8.57137 1.0446L8.8711 1.03906Z" stroke="#F8F8F8" strokeWidth="2.08148" strokeMiterlimit="10"/>
            </svg>
          </li>
        </ul>
      </div>

      <div className={styles.block}>
        <div className={styles.heading}>
          <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Level 1</AniLink>
        </div>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Football</AniLink>
          </li>
          <ul>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Clothing</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Footwear</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Shirt Personalisation</AniLink>
            </li>
          </ul>
        </ul>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Running</AniLink>
          </li>
          <ul>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Clothing</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Footwear</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Gait Analysis</AniLink>
            </li>
          </ul>
        </ul>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Kids</AniLink>
          </li>
          <ul>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Clothing</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Footwear</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Foot Measuring</AniLink>
            </li>
          </ul>
        </ul>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Sports</AniLink>
          </li>
          <ul>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Golf</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Tennis</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Cricket</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Skate</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Boxing</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/level-1">Home Gym</AniLink>
            </li>
          </ul>
        </ul>
        <ul className={styles.key}>
          <li>
            <svg width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.7959 4.16945C14.7959 3.58644 14.9665 3.01652 15.286 2.53176C15.6056 2.047 16.0598 1.66918 16.5912 1.44607C17.1227 1.22296 17.7074 1.16458 18.2716 1.27832C18.8357 1.39206 19.3539 1.67281 19.7607 2.08506C20.1674 2.49732 20.4444 3.02256 20.5566 3.59437C20.6688 4.16618 20.6112 4.75888 20.3911 5.29751C20.171 5.83615 19.7982 6.29653 19.3199 6.62043C18.8417 6.94434 18.2794 7.11722 17.7042 7.11722V10.8587" stroke="#F8F8F8" strokeWidth="2.19022" strokeMiterlimit="10"/>
              <path d="M17.7696 24.0008H3.38916C1.97565 24.0008 1.46748 21.5787 2.68824 20.6514L8.02689 16.5892L15.8012 10.6793C16.3862 10.2252 17.0703 9.9834 17.7696 9.9834C18.4689 9.9834 19.153 10.2252 19.738 10.6793L27.5123 16.5892L32.851 20.6514C34.0717 21.5787 33.5636 24.0008 32.1501 24.0008H17.7696Z" stroke="#F8F8F8" strokeWidth="2.19022" strokeMiterlimit="10"/>
            </svg>
          </li>
          <li>
            <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.9 9.98012C10.82 10.5301 10.46 10.8501 9.92 10.8501C9.25 10.8501 8.82 10.6901 8.15 10.6901C7.86 10.6901 7.58 10.7201 7.31 10.7801C7.97 10.5101 8.31 10.0001 8.31 9.30012C8.31 9.13012 8.3 8.98012 8.28 8.84012H10.2V8.03012H8.1C8.04 7.76012 7.99 7.49012 7.99 7.16012C7.99 6.45012 8.39 6.08012 9.19 6.08012C9.86 6.08012 10.3 6.43012 10.42 7.05012L12.05 6.72012C11.79 5.51012 10.8 4.87012 9.15 4.87012C7.4 4.87012 6.33 5.72012 6.33 7.17012C6.33 7.49012 6.38 7.76012 6.44 8.03012H5.57V8.84012H6.62C6.66 9.04012 6.68 9.26012 6.68 9.50012C6.68 10.3501 6.22 10.8901 5.25 11.1601L5.57 12.0801C6.22 11.9601 6.88 11.8901 7.47 11.8901C8.69 11.8901 9.2 12.1701 10.24 12.1701C11.45 12.1701 12.3 11.4301 12.49 10.2001L10.9 9.98012Z" fill="#F8F8F8"/>
              <path d="M8.8711 1.03906H9.16528C15.0378 1.23888 18.6013 7.6998 15.7483 12.823L9.93127 23.4414C9.8045 23.6019 9.643 23.7317 9.4589 23.8209C9.27481 23.9101 9.07289 23.9564 8.86832 23.9564C8.66375 23.9564 8.46184 23.9101 8.27775 23.8209C8.09365 23.7317 7.93215 23.6019 7.80538 23.4414L1.98833 12.8286C-0.831384 7.6776 2.69881 1.24442 8.57137 1.0446L8.8711 1.03906Z" stroke="#F8F8F8" strokeWidth="2.08148" strokeMiterlimit="10"/>
            </svg>
          </li>
        </ul>
      </div>

      <div className={styles.block}>
        <div className={styles.heading}>
          <AniLink cover bg={cover} direction="up" to="/oxford-st/">Level 0</AniLink>
        </div>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/oxford-st/">Mens</AniLink>
          </li>
          <ul>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/">Clothing</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/">Footwear</AniLink>
            </li>
          </ul>
        </ul>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/oxford-st/">Womens</AniLink>
          </li>
          <ul>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/">Clothing</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/">Footwear</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/">Bra Studio</AniLink>
            </li>
            <li>
              <AniLink cover bg={cover} direction="up" to="/oxford-st/">Accessories</AniLink>
            </li>
          </ul>
        </ul>
        <ul className={styles.key}>
          <li>
            <svg width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.7959 4.16945C14.7959 3.58644 14.9665 3.01652 15.286 2.53176C15.6056 2.047 16.0598 1.66918 16.5912 1.44607C17.1227 1.22296 17.7074 1.16458 18.2716 1.27832C18.8357 1.39206 19.3539 1.67281 19.7607 2.08506C20.1674 2.49732 20.4444 3.02256 20.5566 3.59437C20.6688 4.16618 20.6112 4.75888 20.3911 5.29751C20.171 5.83615 19.7982 6.29653 19.3199 6.62043C18.8417 6.94434 18.2794 7.11722 17.7042 7.11722V10.8587" stroke="#F8F8F8" strokeWidth="2.19022" strokeMiterlimit="10"/>
              <path d="M17.7696 24.0008H3.38916C1.97565 24.0008 1.46748 21.5787 2.68824 20.6514L8.02689 16.5892L15.8012 10.6793C16.3862 10.2252 17.0703 9.9834 17.7696 9.9834C18.4689 9.9834 19.153 10.2252 19.738 10.6793L27.5123 16.5892L32.851 20.6514C34.0717 21.5787 33.5636 24.0008 32.1501 24.0008H17.7696Z" stroke="#F8F8F8" strokeWidth="2.19022" strokeMiterlimit="10"/>
            </svg>
          </li>
          <li>
            <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.9 9.98012C10.82 10.5301 10.46 10.8501 9.92 10.8501C9.25 10.8501 8.82 10.6901 8.15 10.6901C7.86 10.6901 7.58 10.7201 7.31 10.7801C7.97 10.5101 8.31 10.0001 8.31 9.30012C8.31 9.13012 8.3 8.98012 8.28 8.84012H10.2V8.03012H8.1C8.04 7.76012 7.99 7.49012 7.99 7.16012C7.99 6.45012 8.39 6.08012 9.19 6.08012C9.86 6.08012 10.3 6.43012 10.42 7.05012L12.05 6.72012C11.79 5.51012 10.8 4.87012 9.15 4.87012C7.4 4.87012 6.33 5.72012 6.33 7.17012C6.33 7.49012 6.38 7.76012 6.44 8.03012H5.57V8.84012H6.62C6.66 9.04012 6.68 9.26012 6.68 9.50012C6.68 10.3501 6.22 10.8901 5.25 11.1601L5.57 12.0801C6.22 11.9601 6.88 11.8901 7.47 11.8901C8.69 11.8901 9.2 12.1701 10.24 12.1701C11.45 12.1701 12.3 11.4301 12.49 10.2001L10.9 9.98012Z" fill="#F8F8F8"/>
              <path d="M8.8711 1.03906H9.16528C15.0378 1.23888 18.6013 7.6998 15.7483 12.823L9.93127 23.4414C9.8045 23.6019 9.643 23.7317 9.4589 23.8209C9.27481 23.9101 9.07289 23.9564 8.86832 23.9564C8.66375 23.9564 8.46184 23.9101 8.27775 23.8209C8.09365 23.7317 7.93215 23.6019 7.80538 23.4414L1.98833 12.8286C-0.831384 7.6776 2.69881 1.24442 8.57137 1.0446L8.8711 1.03906Z" stroke="#F8F8F8" strokeWidth="2.08148" strokeMiterlimit="10"/>
            </svg>
          </li>
        </ul>
      </div>

      <div className={styles.block}>
        <div className={styles.heading}>
          <AniLink cover bg={cover} direction="up" to="/oxford-st/level-minus-1">Level -1</AniLink>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" fill="white"/>
            <path d="M17.53 22.0372C18.186 22.4669 18.8306 22.6576 19.725 22.6576C20.6792 22.6576 21.3594 22.3237 21.3594 21.7988C21.3594 21.2616 20.8941 21.0111 19.5102 20.8084C17.7684 20.558 16.7544 20.0328 16.7544 18.3508C16.7544 16.8474 18.1739 15.7139 20.095 15.7139C21.3594 15.7139 22.2545 15.9643 23.1133 16.5015L22.0873 17.8499C21.2406 17.3966 20.7034 17.3013 19.9157 17.3013C19.128 17.3013 18.4599 17.6713 18.4599 18.1967C18.4599 18.8172 18.9253 18.9958 20.4287 19.2228C22.3258 19.5088 23.2086 20.2244 23.2086 21.5495C23.2086 23.2436 21.5742 24.2575 19.6652 24.2575C18.4962 24.2575 17.3627 24.0668 16.3965 23.362L17.53 22.0372V22.0372Z" fill="#161616"/>
            <path d="M11.7442 24.2559C9.54914 24.2559 8.21289 23.0271 8.21289 20.5451V15.9525H10.0265V20.4379C10.0265 21.846 10.7424 22.5732 11.8758 22.5732C12.9971 22.5732 13.7129 21.8453 13.7129 20.4976V15.9521H15.5266V20.486C15.5259 22.9791 13.9627 24.2556 11.7441 24.2556L11.7442 24.2559Z" fill="#161616"/>
            <path d="M28.2683 24.2557C25.9181 24.2557 24.2236 22.669 24.2236 20.0684C24.2236 17.7303 26.001 15.7139 28.5305 15.7139C30.0815 15.7139 31.0479 16.2268 31.6562 16.8474L30.5825 18.1836C29.962 17.6229 29.3779 17.3959 28.5184 17.3959C27.1821 17.3959 26.1204 18.4938 26.1204 19.9488C26.1204 21.3451 26.9672 22.5736 28.5062 22.5736C29.4604 22.5736 30.0331 22.299 30.6656 21.7148L31.6917 22.8838C30.8449 23.7426 29.8666 24.2557 28.2678 24.2557H28.2683Z" fill="#161616"/>
          </svg>
        </div>
        <ul className={styles.sublist}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/oxford-st/level-minus-1">Sneaker Zone</AniLink>
          </li>
          <li>
            <AniLink cover bg={cover} direction="up" to="/oxford-st/level-minus-1">Menswear</AniLink>
          </li>
          <li>
            <AniLink cover bg={cover} direction="up" to="/oxford-st/level-minus-1">Lounge</AniLink>
          </li>
          <li>
            <AniLink cover bg={cover} direction="up" to="/oxford-st/level-minus-1">Denim</AniLink>
          </li>
          <li>
            <AniLink cover bg={cover} direction="up" to="/oxford-st/level-minus-1">Womenswear</AniLink>
          </li>
          <li>
            <AniLink cover bg={cover} direction="up" to="/oxford-st/level-minus-1">Kids</AniLink>
          </li>
        </ul>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/oxford-st/level-minus-1">Customer Services</AniLink>
          </li>
        </ul>
        <ul className={styles.list}>
          <li>
            <AniLink cover bg={cover} direction="up" to="/oxford-st/level-minus-1">Click &amp; Collect</AniLink>
          </li>
        </ul>
        <ul className={styles.key}>
          <li>
            <svg width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.7959 4.16945C14.7959 3.58644 14.9665 3.01652 15.286 2.53176C15.6056 2.047 16.0598 1.66918 16.5912 1.44607C17.1227 1.22296 17.7074 1.16458 18.2716 1.27832C18.8357 1.39206 19.3539 1.67281 19.7607 2.08506C20.1674 2.49732 20.4444 3.02256 20.5566 3.59437C20.6688 4.16618 20.6112 4.75888 20.3911 5.29751C20.171 5.83615 19.7982 6.29653 19.3199 6.62043C18.8417 6.94434 18.2794 7.11722 17.7042 7.11722V10.8587" stroke="#F8F8F8" strokeWidth="2.19022" strokeMiterlimit="10"/>
              <path d="M17.7696 24.0008H3.38916C1.97565 24.0008 1.46748 21.5787 2.68824 20.6514L8.02689 16.5892L15.8012 10.6793C16.3862 10.2252 17.0703 9.9834 17.7696 9.9834C18.4689 9.9834 19.153 10.2252 19.738 10.6793L27.5123 16.5892L32.851 20.6514C34.0717 21.5787 33.5636 24.0008 32.1501 24.0008H17.7696Z" stroke="#F8F8F8" strokeWidth="2.19022" strokeMiterlimit="10"/>
            </svg>
          </li>
          <li>
            <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.9 9.98012C10.82 10.5301 10.46 10.8501 9.92 10.8501C9.25 10.8501 8.82 10.6901 8.15 10.6901C7.86 10.6901 7.58 10.7201 7.31 10.7801C7.97 10.5101 8.31 10.0001 8.31 9.30012C8.31 9.13012 8.3 8.98012 8.28 8.84012H10.2V8.03012H8.1C8.04 7.76012 7.99 7.49012 7.99 7.16012C7.99 6.45012 8.39 6.08012 9.19 6.08012C9.86 6.08012 10.3 6.43012 10.42 7.05012L12.05 6.72012C11.79 5.51012 10.8 4.87012 9.15 4.87012C7.4 4.87012 6.33 5.72012 6.33 7.17012C6.33 7.49012 6.38 7.76012 6.44 8.03012H5.57V8.84012H6.62C6.66 9.04012 6.68 9.26012 6.68 9.50012C6.68 10.3501 6.22 10.8901 5.25 11.1601L5.57 12.0801C6.22 11.9601 6.88 11.8901 7.47 11.8901C8.69 11.8901 9.2 12.1701 10.24 12.1701C11.45 12.1701 12.3 11.4301 12.49 10.2001L10.9 9.98012Z" fill="#F8F8F8"/>
              <path d="M8.8711 1.03906H9.16528C15.0378 1.23888 18.6013 7.6998 15.7483 12.823L9.93127 23.4414C9.8045 23.6019 9.643 23.7317 9.4589 23.8209C9.27481 23.9101 9.07289 23.9564 8.86832 23.9564C8.66375 23.9564 8.46184 23.9101 8.27775 23.8209C8.09365 23.7317 7.93215 23.6019 7.80538 23.4414L1.98833 12.8286C-0.831384 7.6776 2.69881 1.24442 8.57137 1.0446L8.8711 1.03906Z" stroke="#F8F8F8" strokeWidth="2.08148" strokeMiterlimit="10"/>
            </svg>
          </li>
          <li>
            <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.9269 14.8406L10.4917 10.119C10.4489 10.1016 10.402 10.0971 10.3568 10.1061C10.3115 10.115 10.2698 10.137 10.2369 10.1694C10.2039 10.2017 10.1812 10.2429 10.1714 10.288C10.1616 10.3332 10.1652 10.3801 10.1818 10.4232L14.9091 22.8584C14.9275 22.9006 14.9578 22.9366 14.9964 22.9618C15.0349 22.9871 15.08 23.0005 15.126 23.0005C15.1721 23.0005 15.2171 22.9871 15.2557 22.9618C15.2942 22.9366 15.3246 22.9006 15.343 22.8584L16.9037 19.7764C16.9217 19.7434 16.947 19.715 16.9779 19.6936C17.0087 19.6721 17.044 19.658 17.0812 19.6526C17.1184 19.6471 17.1563 19.6504 17.192 19.6621C17.2277 19.6738 17.2601 19.6936 17.2868 19.72L21.0506 23.4838L23.5749 20.9596L19.8054 17.1958C19.7792 17.1687 19.7597 17.1359 19.7484 17.0999C19.7372 17.064 19.7344 17.0259 19.7404 16.9887C19.7465 16.9515 19.7611 16.9162 19.7831 16.8857C19.8051 16.8551 19.834 16.8301 19.8674 16.8127L22.9494 15.2519C22.9847 15.2293 23.0134 15.1978 23.0325 15.1605C23.0516 15.1232 23.0604 15.0815 23.0581 15.0397C23.0558 14.9979 23.0425 14.9574 23.0194 14.9224C22.9964 14.8875 22.9644 14.8592 22.9269 14.8406Z" stroke="#F8F8F8" strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M6.81174 6.71041L2.87891 2.7832" stroke="#F8F8F8" strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M5.56119 10.001H0" stroke="#F8F8F8" strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M10.0967 5.56119V0" stroke="#F8F8F8" strokeWidth="2" strokeMiterlimit="10"/>
            </svg>
          </li>
        </ul>
      </div>

      <div className={styles.keyblock}>
        <div className={styles.heading}>
          <p>Key</p>
        </div>
        <ul className={styles.list}>
          <li>
            <svg width="34" height="25" viewBox="0 0 34 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.7959 3.94777C13.7959 3.36476 13.9665 2.79484 14.286 2.31008C14.6056 1.82532 15.0598 1.4475 15.5912 1.22439C16.1227 1.00128 16.7074 0.942901 17.2716 1.05664C17.8357 1.17038 18.3539 1.45113 18.7607 1.86338C19.1674 2.27564 19.4444 2.80088 19.5566 3.37269C19.6688 3.9445 19.6112 4.5372 19.3911 5.07583C19.171 5.61447 18.7982 6.07485 18.3199 6.39875C17.8417 6.72266 17.2794 6.89554 16.7042 6.89554V10.637" stroke="#1C1C1C" strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M16.7696 23.7791H2.38916C0.975646 23.7791 0.467476 21.357 1.68824 20.4297L7.02688 16.3675L14.8012 10.4576C15.3862 10.0036 16.0703 9.76172 16.7696 9.76172C17.4689 9.76172 18.153 10.0036 18.738 10.4576L26.5123 16.3675L31.851 20.4297C33.0717 21.357 32.5636 23.7791 31.1501 23.7791H16.7696Z" stroke="#1C1C1C" strokeWidth="2" strokeMiterlimit="10"/>
            </svg>
            <p>Fitting Room</p>
          </li>
          <li>
            <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.9 9.98012C10.82 10.5301 10.46 10.8501 9.92 10.8501C9.25 10.8501 8.82 10.6901 8.15 10.6901C7.86 10.6901 7.58 10.7201 7.31 10.7801C7.97 10.5101 8.31 10.0001 8.31 9.30012C8.31 9.13012 8.3 8.98012 8.28 8.84012H10.2V8.03012H8.1C8.04 7.76012 7.99 7.49012 7.99 7.16012C7.99 6.45012 8.39 6.08012 9.19 6.08012C9.86 6.08012 10.3 6.43012 10.42 7.05012L12.05 6.72012C11.79 5.51012 10.8 4.87012 9.15 4.87012C7.4 4.87012 6.33 5.72012 6.33 7.17012C6.33 7.49012 6.38 7.76012 6.44 8.03012H5.57V8.84012H6.62C6.66 9.04012 6.68 9.26012 6.68 9.50012C6.68 10.3501 6.22 10.8901 5.25 11.1601L5.57 12.0801C6.22 11.9601 6.88 11.8901 7.47 11.8901C8.69 11.8901 9.2 12.1701 10.24 12.1701C11.45 12.1701 12.3 11.4301 12.49 10.2001L10.9 9.98012Z" fill="#1C1C1C"/>
              <path d="M8.91407 1.03906H9.20825C15.0808 1.23888 18.6443 7.6998 15.7913 12.823L9.97424 23.4414C9.84747 23.6019 9.68597 23.7317 9.50187 23.8209C9.31778 23.9101 9.11586 23.9564 8.91129 23.9564C8.70672 23.9564 8.50481 23.9101 8.32072 23.8209C8.13662 23.7317 7.97512 23.6019 7.84835 23.4414L2.0313 12.8286C-0.788415 7.6776 2.74178 1.24442 8.61434 1.0446L8.91407 1.03906Z" stroke="#1C1C1C" strokeWidth="2" strokeMiterlimit="10"/>
            </svg>
            <p>Cash Desk</p>
          </li>
          <li>
            <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.9269 14.8406L10.4917 10.119C10.4489 10.1016 10.402 10.0971 10.3568 10.1061C10.3115 10.115 10.2698 10.137 10.2369 10.1694C10.2039 10.2017 10.1812 10.2429 10.1714 10.288C10.1616 10.3332 10.1652 10.3801 10.1818 10.4232L14.9091 22.8584C14.9275 22.9006 14.9578 22.9366 14.9964 22.9618C15.0349 22.9871 15.08 23.0005 15.126 23.0005C15.1721 23.0005 15.2171 22.9871 15.2557 22.9618C15.2942 22.9366 15.3246 22.9006 15.343 22.8584L16.9037 19.7764C16.9217 19.7434 16.947 19.715 16.9779 19.6936C17.0087 19.6721 17.044 19.658 17.0812 19.6526C17.1184 19.6471 17.1563 19.6504 17.192 19.6621C17.2277 19.6738 17.2601 19.6936 17.2868 19.72L21.0506 23.4838L23.5749 20.9596L19.8054 17.1958C19.7792 17.1687 19.7597 17.1359 19.7484 17.0999C19.7372 17.064 19.7344 17.0259 19.7404 16.9887C19.7465 16.9515 19.7611 16.9162 19.7831 16.8857C19.8051 16.8551 19.834 16.8301 19.8674 16.8127L22.9494 15.2519C22.9847 15.2293 23.0134 15.1978 23.0325 15.1605C23.0516 15.1232 23.0604 15.0815 23.0581 15.0397C23.0558 14.9979 23.0425 14.9574 23.0194 14.9224C22.9964 14.8875 22.9644 14.8592 22.9269 14.8406Z" stroke="#1C1C1C" strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M6.81174 6.71041L2.87891 2.7832" stroke="#1C1C1C" strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M5.56119 10.001H0" stroke="#1C1C1C" strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M10.0967 5.56119V0" stroke="#1C1C1C" strokeWidth="2" strokeMiterlimit="10"/>
            </svg>
            <p>Click and Collect</p>
          </li>
        </ul>
      </div>

    </nav>

  )
}

export default Navigation
